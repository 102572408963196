import { addMiddleware, ISerializedActionCall, onAction, onPatch } from 'mobx-state-tree'
import { getCookie, setCookie } from '../../app/helpers/utils'
import { ProductUpdateQuantityInterface } from '../model/ProductUpdateQuantityInterface'
import { PaymentMethodStoreInterfaceSnapshotOut } from '../state-manager/PaymentMethodStore'
import { ShopForPickUpInterfaceSnapshotOut } from '../state-manager/ShopForPickUp'
import { ShoppingCartHeaderStoreInterfaceSnapshotOut } from '../state-manager/ShoppingCartHeaderStore'
import { ShoppingCartStoreInterface } from '../state-manager/ShoppingCartStore'
import { datadogService } from './DatadogService'
import { GTMEventServiceInterface } from './GTMEvent'

export interface GTMRootStoreMiddlewareInterface {
  middleware(): void
  listenAction(): void
  listenPatch(): void
}

export class GTMRootStoreMiddleware implements GTMRootStoreMiddlewareInterface {
  constructor(private rootStore: ShoppingCartStoreInterface, private gtmevent: GTMEventServiceInterface) {
    this.rootStore = rootStore
    this.gtmevent = gtmevent
  }
  private getArgs(action: ISerializedActionCall): any {
    const args: Array<any> = Object.assign({}, action.args)
    return args[0] ? args[0] : {}
  }
  listenAction() {
    onAction(this.rootStore, (action: ISerializedActionCall) => {
      switch (action.name) {
        case 'setHeader':
          const header: ShoppingCartHeaderStoreInterfaceSnapshotOut = this.getArgs(action)
          if (header.couponCode) {
            this.gtmevent.appliedCoupon(header.couponCode)
          }
          break
        case 'setPaymentMethod':
          const paymentMethod: PaymentMethodStoreInterfaceSnapshotOut = this.getArgs(action)
          this.gtmevent.checkoutStep(4, paymentMethod.code)
          break
        case 'setHeaderPickUpStore':
          const shippingData: ShopForPickUpInterfaceSnapshotOut = this.rootStore.shopForPickUp
          this.gtmevent.checkoutDelivery('pick_up_store', `${shippingData.name}_${shippingData.area}`)
          break
        case 'setHeaderMailOrder':
          this.gtmevent.checkoutDelivery('home')
          break
        case 'updateProductQty':
          const updateQty: ProductUpdateQuantityInterface = this.getArgs(action)
          const { newQty, initQty, productId } = updateQty
          if (newQty > initQty) {
            this.gtmevent.addProduct(productId)
            this.gtmevent.cartInteraction('add_qty')
          }
          if (newQty < initQty) {
            this.gtmevent.removeProduct(productId)
            this.gtmevent.cartInteraction('remove_qty')
          }
          break
        case 'removeProduct':
          const { id } = this.getArgs(action)
          this.gtmevent.removeProduct(id)
          break
        case 'cartInteraction':
          this.gtmevent.cartInteraction(this.getArgs(action))
          break
        case 'shareWithFriends':
          this.gtmevent.shareWithFriends(this.getArgs(action))
          break
        case 'thankYouPageInteraction':
          this.gtmevent.thankYouPageInteraction(this.getArgs(action))
          break
        case 'checkoutPaymentError':
          this.gtmevent.checkoutPaymentError()
          break
        case 'purchaseEvent':
          const cookieKey = `ShoppingCart_${action.name}`
          if (getCookie(cookieKey) !== this.rootStore.header.id) {
            setCookie(cookieKey, this.rootStore.header.id, 3)
            this.gtmevent.purchase()
            datadogService.triggerEvent('web.purchase')
            if (this.rootStore.consumerUserMarketingData.hasAlreadyOrdered) {
              datadogService.triggerEvent('web.retention_purchase')
            } else {
              datadogService.triggerEvent('web.acquisition_purchase')
            }
          }
          break
      }
    })
  }
  listenPatch() {
    onPatch(this.rootStore, (patch) => {
      const { op, path, value } = patch
      if (op === 'replace') {
        if (path === '/progress') {
          switch (value) {
            default:
              this.gtmevent.checkoutStep(value)
              break
            case 3:
              this.gtmevent.checkoutStep(value, this.rootStore.shippingMethod.code)
              break
            case 4:
              // only on succesful payment => src/domain/components/payment/PaymentMethods.tsx
              break
          }
        }
        if (path === '/queryStringParamsAccepted/productsAdded' && !!value) {
          this.gtmevent.addProduct(value)
          datadogService.triggerEvent('web.cart_add_success', {
            isEntryLevel: this.rootStore.userTypes?.types?.includes('EntryLevelUser') || false
          })
        }
      }
    })
  }
  middleware() {
    addMiddleware(this.rootStore, (call: any, next: any) => {
      next(call, (value: any) => {
        return value
      })
    })
  }
}
