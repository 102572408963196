import { from, Observable } from 'rxjs'
import { switchMap } from 'rxjs/operators'
import { getCountryAndRegion } from '../../../app/helpers/getCountryAndRegion'
import { bodyParam, XHROptions } from '../../../app/service/XHRRequest/XHRRequestObservable'
import { PaymentChoseedApiResultInterface } from '../../adapter/PaymentChoosedAdapter'
import ApiEnv from '../../environment/api-env'
import OrderApiResult from '../../model/api-result/OrderApiResult'
import { OrderHeaderApiResultInterface } from '../../model/api-result/ShoppingCartHeaderApiResultInterface'
import { PaymentsMethodAcceptedTypes } from '../../model/PaymentMethodAcceptedTypes'
import { AbstractApiClient } from '../AbstractApiClient'

export interface OrdersConsumerHeaderApiClientInterface {
  postOrderState$(): Observable<OrderHeaderApiResultInterface>
  patchPaymentType$(type: PaymentsMethodAcceptedTypes): Observable<PaymentChoseedApiResultInterface>
  getOrderState$(): Observable<OrderApiResult>
  patchBackToCart$(backToCart: boolean): Observable<any>
}

export class OrdersConsumerHeaderApiClient extends AbstractApiClient {
  private guid: string = ''
  constructor({ xhrRequestObv, guid, querystring }: any) {
    super(xhrRequestObv, querystring)
    this.guid = guid
  }

  /**
   * @desc set order status
   * @param state
   */
  postOrderState$(): Observable<OrderHeaderApiResultInterface> {
    return from(getCountryAndRegion()).pipe(
      switchMap(({ country, region }) => {
        const bodyParams: bodyParam[] = [
          { key: 'cartId', value: this.guid },
          { key: 'country', value: country },
          { key: 'region', value: region }
        ]

        const params: XHROptions = {
          method: 'POST',
          body: bodyParams,
          url: `${ApiEnv.orderState.url}`
        }

        return this.get$(params)
      })
    )
  }

  /**
   *
   * @param type patch method of payment
   */
  patchPaymentType$(type: PaymentsMethodAcceptedTypes): Observable<PaymentChoseedApiResultInterface> {
    const bodyParams = [{ key: 'payType', value: type }]
    const params: XHROptions = {
      method: 'PATCH',
      body: bodyParams,
      url: `${ApiEnv.orderState.url}${this.guid}`
    }
    return this.get$(params)
  }

  /**
   * @desc get full order state (order ≠ cart)
   */
  getOrderState$(): Observable<OrderApiResult> {
    const params: XHROptions = {
      url: `${ApiEnv.orderState.url}${this.guid}`
    }
    return this.get$(params)
  }

  /**
   * @desc return to state "new" from another states
   * @param backToCart
   */
  patchBackToCart$(backToCart: boolean = true): Observable<any> {
    const bodyParams = [{ key: 'backToCart', value: backToCart }]
    const params: XHROptions = {
      method: 'PATCH',
      body: bodyParams,
      url: `${ApiEnv.orderState.url}${this.guid}`
    }
    return this.get$(params)
  }
}
