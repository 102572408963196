// GeoIP types
interface GeoIPCountry {
  iso_code: string
  names?: { en: string }
}

interface GeoIPSubdivision {
  iso_code: string
  names?: { en: string }
}

interface GeoIPLocation {
  accuracy_radius?: number
  latitude?: number
  longitude?: number
  time_zone?: string
}

export interface GeoIPCityResponse {
  city?: { names?: { en: string } }
  country: GeoIPCountry
  registered_country?: GeoIPCountry
  location?: GeoIPLocation
  subdivisions?: GeoIPSubdivision[]
}

// window extension
/**
 * Interrogates the MaxMind GeoIP API to get the country and region of the user.
 * If the request fails, it returns unknown values.
 * @returns {Promise<{ country: string; region: string }>}
 */
export async function getCountryAndRegion(): Promise<{ country: string | null; region: string | null }> {
  const defaultCountryAndRegion = {
    country: null,
    region: null
  }

  try {
    await loadGeoIPScript()
  } catch (error) {
    console.error('Error loading geoip2 script:', error)
    return defaultCountryAndRegion
  }

  return new Promise((resolve) => {
    if (!window.geoip2) {
      console.error('geoip2 library not loaded')
      return resolve(defaultCountryAndRegion)
    }

    window.geoip2.city(
      (data) => {
        const country = data.country?.names?.en?.toLowerCase() || null
        const region = data.subdivisions?.[0]?.names?.en?.toLowerCase() || null
        resolve({ country, region })
      },
      (error: any) => {
        console.error('Error fetching geo data:', error)
        resolve(defaultCountryAndRegion)
      }
    )
  })
}

/**
 * Loads the MaxMind GeoIP script.
 * @returns {Promise<void>}
 */
function loadGeoIPScript(): Promise<void> {
  return new Promise((resolve, reject) => {
    if (window.geoip2) {
      resolve()
      return
    }

    const script = document.createElement('script')
    script.src = 'https://geoip-js.com/js/apis/geoip2/v2.1/geoip2.js'
    script.type = 'text/javascript'
    script.async = true
    script.onload = () => resolve()
    script.onerror = () => reject()
    document.head.appendChild(script)
  })
}
