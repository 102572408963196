import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { Observable, Observer, throwError } from 'rxjs'
import { catchError } from 'rxjs/operators'
import { XHR_TIMEOUT } from '../../../domain/environment/const'

export interface bodyParam {
  key: string
  value: string | number | boolean | null
}

export interface XHROptions {
  url: string
  responseType?: XMLHttpRequestResponseType
  CustumRequestHeader?: CustomRequestHeaderItemInterface[]
  method?: XHRMethod
  body?: bodyParam[] | null
  timeout?: number
}

/**
 * @interface
 * @desc method required for XHRRequestObservable
 */
export interface XHRRequestObservableInterface {
  addCustomRequestHeaderItem(customHeaderItem: CustomRequestHeaderItemInterface): void
  XHR$(params: XHROptions): Observable<any>
  clearCustomRequestHeader(): void
  getCustomHeader(): Array<CustomRequestHeaderItemInterface>
}

/**
 * @interface
 * @desc single item compatible for add a new custom request header item key/value
 */
export interface CustomRequestHeaderItemInterface {
  key: string
  value: string
}

export type XHRMethod = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH'

/**
 * @class
 * @desc return an observable from xhr call with response
 */
export class XHRRequestObservable implements XHRRequestObservableInterface {
  private customRequestHeader: Array<CustomRequestHeaderItemInterface> = []
  /**
   * @desc add to list or requestHeader custom item a new custom item key/value
   * @method
   * @param customHeaderItem
   * @return {void}
   */
  addCustomRequestHeaderItem(customHeaderItem: CustomRequestHeaderItemInterface): void {
    this.customRequestHeader.push(customHeaderItem)
  }
  clearCustomRequestHeader() {
    this.customRequestHeader = []
  }
  getCustomHeader(): Array<CustomRequestHeaderItemInterface> {
    return this.customRequestHeader
  }
  /**
   * @desc return xhr request encapsualted in observable
   * @method
   * @param url
   * @param responseType
   * @param tempCustumRequestHeader
   * @return {Observable}
   */
  XHR$ = ({ url, responseType, CustumRequestHeader, method, body, timeout }: XHROptions): Observable<any> => {
    return new Observable((observer: Observer<any>) => {
      if (!url) {
        observer.error(new Error('Url not valid or empty'))
      }

      const config: AxiosRequestConfig = {
        method: method,
        url: url,
        timeout: timeout || XHR_TIMEOUT
      }

      if (this.customRequestHeader || CustumRequestHeader) {
        const headers = {}

        if (this.customRequestHeader) {
          this.customRequestHeader.forEach((item: bodyParam) => {
            Object.assign(headers, { [item.key]: item.value })
          })
        }

        if (CustumRequestHeader) {
          CustumRequestHeader.forEach((item: bodyParam) => {
            Object.assign(headers, { [item.key]: item.value })
          })
        }

        Object.assign(config, { headers: headers })
      }

      if (responseType) {
        Object.assign(config, { responseType: responseType })
      }

      if (body && body.length > 0) {
        const data = {}
        body.forEach((item: bodyParam) => {
          Object.assign(data, { [item.key]: item.value })
        })
        Object.assign(config, { data: data })
      }

      axios(config)
        .then((response: AxiosResponse) => {
          observer.next(response)
          observer.complete()
        })
        .catch((error: Error) => {
          observer.error(error)
        })
        .then(() => {
          observer.complete()
        })
    }).pipe(
      catchError((error: Error) => {
        return throwError(error)
      })
    )
  }
}
